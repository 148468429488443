<template>

  <y-card-subtitle>
    Historique des matchs
  </y-card-subtitle>

  <ul class="divide-y">
    <li
      class="p-4 relative"
      v-for="game in getEventGames"
      :key="game.id">

      <div class="absolute border-l h-full top-0 left-1/2" />
      <p class="relative text-xs text-center text-gray-400 bg-white">
        {{ $filters.convertTimeStampToFormatedDate(game.date) }}
      </p>
      <div class="relative flex items-center justify-center mt-2 text-sm">

        <y-badge class="absolute left-0 top-0">
          +{{ $filters.pluralize(getPoints(game.winners), 'pt') }}
        </y-badge>
        <y-badge
          class="absolute right-0 top-0"
          color="red-500">
          {{ $filters.pluralize(getPoints(game.loosers), 'pt') }}
        </y-badge>

        <div class="text-right flex-1 mr-4">
          <div class="leading-0 mb-1">
            <y-avatar
              class="-ml-2"
              size="6"
              v-for="(winner, id) in game.winners"
              :key="id"
              :player="getEventPlayersById(id)" />
          </div>
          <p>
            <template
              v-for="(winner, id) in game.winners"
              :key="id">
              {{ getEventPlayersById(id)?.name }}<span class="last:hidden">, </span>
            </template>
          </p>
        </div>
        <div class="text-left flex-1 ml-4">
          <div class="leading-0 mb-1">
            <y-avatar
              class="-mr-2"
              size="6"
              v-for="(looser, id) in game.loosers"
              :key="id"
              :player="getEventPlayersById(id)" />
          </div>
          <p>
            <template
              v-for="(looser, id) in game.loosers"
              :key="id">
              {{ getEventPlayersById(id)?.name }}<span class="last:hidden">, </span>
            </template>
          </p>
        </div>
      </div>

    </li>
  </ul>

</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { sortFirebaseObjectBy } from '@/classes/Tools';
import Config from '@/config';

import YCardSubtitle from '@/UI/YCardSubtitle.vue';
import YAvatar from '@/UI/YAvatar.vue';
import YBadge from '@/UI/YBadge.vue';

export default {

  components: {
    YCardSubtitle,
    YAvatar,
    YBadge,
  },

  setup() {
    const store = useStore();
    const getEventGames = computed(() => sortFirebaseObjectBy(store.getters.getEventGames, 'date').reverse());
    const getEventPlayersById = (id) => store.getters.getEventPlayersById(id);

    const getPoints = (players) => {
      const value = players[Object.keys(players)[0]];
      return value * Config.points[value < 0 ? 'defeat' : 'victory'];
    };

    return {
      getEventGames,
      getEventPlayersById,
      getPoints,
    };
  },

};
</script>
